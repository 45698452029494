<template>
    <div>
      <base-page-title :titleText="pageTitle" />
      <service-predict-form
        :formType="formType"
        :service="service"
        @setService="createServicePredict"
      />
    </div>
  </template>

<script>
import ServicePredictForm from "./../components/ServicesPredict/ServicePredictForm.vue"
export default {
    components:{
      ServicePredictForm,
    },
    data(){
        return {
            pageTitle:"",
            formType: 'NEW',
            service:{
                name:"",
                description:"",
                numAnalysis:0 // 0 means unlimited
            }
        }
    },
    methods:{
      createServicePredict(service){
        this.$store.commit("loader/SET_LOADER",{});
        this.$store.dispatch("servicePredict/createService",service)
        .then(()=>{
          this.$router.push({name:"Services"})
        })
          .catch((e)=>{
            console.log("Error while creating service: ",e);
          }).finally(() => this.$store.dispatch("loader/close"));

      }
    }
}

</script>